import {
    Helpers
} from './Helpers';

export const Global = {
    init: function () {
        this.initEvents();
        this.initSwiper();
    },
    initEvents: function () {
        $('body').on('scroll', function () {
            $('body').css('--scroll-y', `${window.scrollY}px`);

            if (window.scrollY > 100) {
                $("#header").addClass("header-sticky")
            } else {
                $("#header").removeClass("header-sticky")
            }
            if (window.scrollY > 70) {
                $("body").addClass("header-mobile-sticky")
            } else {
                $("body").removeClass("header-mobile-sticky")
            }
        });


        // fiche produit accordion
        $(document).on('click', '.fp-accordion-title', function(event) {
            if (!$(this).hasClass("open")) {
                $(this).toggleClass('open');
                $(this).next('.fp-accordion-body').slideDown();
            } else {
                $(this).removeClass('open');
                $(this).next('.fp-accordion-body').slideUp();
            }
        });

    },

    initSwiper: function (selector) { 
        const $container = $(selector + ' .elementor-container');
        const $wrapper = $container.find('.elementor-row');
        $container.addClass('swiper-container')
        $wrapper.addClass('swiper-wrapper')
        $wrapper.find('> div.elementor-column').addClass('swiper-slide');
        $wrapper.after($('<div>', {class: 'swiper-pagination'}));
        new Swiper($container.get(0), {
            slidesPerView: 4,
            slidesPerGroup: 2,
            spaceBetween: 30,
            horizontal: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                '@0.00': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerGroup: 2,
                },
                '@0.50': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerGroup: 2,
                },
                '@1.00': {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                '@1.50': {
                    slidesPerView: 4,
                    spaceBetween: 50,
                },
            },
        });
    }
};