export const Helpers = {
    overflowHidden: function () {
        const $body = $('body');
        const scrollY = $body.css('--scroll-y');
        $body.css({
            'position': 'fixed',
            'top': `-${scrollY}`
        });
    },

    overflowInitial: function () {
        const $html = $('html');
        const $body = $html.find('body');
        const scrollY = $body.css('top');
        $body.css({position: '', top: ''});
        $html.css('scroll-behavior', 'unset');
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        $html.css('scroll-behavior', '');
    }
};
