export const Header = {
    init: function () {
        this.$desktopLanguageSelector = $('div#desktop-header div#language_selector');
        this.$mobileLanguageSelector = $('div#mobile-header div#language_selector');

        this.initEvents();
        this.initLanguageSelector();
        this.initHomeHeaderChange();
        this.menu_Mobile();
        this.stickyMenuOnScroll();
        this.sommaire();
        this.MenuElementsHover();
        this.CompoBlocHover();
    },

    initEvents: function () {
        //Allow hover on language selector
        this.$desktopLanguageSelector.on('mouseenter mouseleave', function (e) {
            let show = e.type === 'mouseenter';
            $(this).find('a.expand-more').attr('aria-expanded', show)
                .siblings('div.dropdown-menu').toggleClass('show', show)
                .parent().toggleClass('show', show);
        });

        this.$mobileLanguageSelector.on('click', function () {
            let show = !$(this).find('div.language-selector').hasClass('show');

            $(this).find('a.expand-more').attr('aria-expanded', show)
                .siblings('div.dropdown-menu').toggleClass('show', show)
                .parent().toggleClass('show', show);

            console.log(show);
        });
    },

    stickyMenuOnScroll: function () {
        function stickyMenu() {
            var window_top = $(window).scrollTop();

            if (window_top >= 100) {
                $('#desktop-header').addClass('stuck stuck-header-element stuck-header');
                $('#header').addClass('header-sticky');

            } else {
                $('#desktop-header').removeClass('stuck stuck-header-element stuck-header');
                $('#header').removeClass('header-sticky');
            }
            if (window_top > 70) {
                $("body").addClass("header-mobile-sticky")

            } else {
                $("body").removeClass("header-mobile-sticky")
            }

        }
        $(function() {
            $(window).scroll(stickyMenu);
            stickyMenu();
        });
    },
    initLanguageSelector: function () {
        this.$desktopLanguageSelector.add(this.$mobileLanguageSelector).find('a.expand-more').each(function () {
            let text = $(this).text();
            $(this).html(text.trim().substring(0, 2));
        });
    },
    initHomeHeaderChange: function () {
        if ($("body#index").length) {
            var headerBody = $('div#desktop-header');
            if (typeof revapi10 !== 'undefined' || typeof revapi7 !== 'undefined') {
                var revapi = typeof revapi7 !== 'undefined' ? revapi7 : revapi10;
                revapi.on('revolution.slide.onchange', function (e, data) {
                    headerBody.toggleClass('dark-header', data.slideIndex !== 1 && data.slideIndex !== 3 && data.slideIndex !== 5);
                });
            }
        }
    },
    menu_Mobile: function () {
        var width = $(window).width();
        if (width < 992) {
            var NavLink = $("#header .col-header-menu .iqitmegamenu-wrapper .cbp-hrmenu .cbp-hrmenu-tab > a.nav-link.cbp-empty-mlink");
            NavLink.click(function (e) {
                e.preventDefault();
                $(this).next().toggleClass("open");
                $(this).toggleClass("open");
                $(this).parent().find('.cbp-hrsub').slideToggle(400);

            });
            var btnClose = "<button class='btn-close'><i class='fas fa-times'></i></button>",
                btnOpen = $("#mobile-header .col-mobile-btn.col-mobile-btn-menu .m-nav-btn"),
                MenuMobile = $("#header .col-header-menu");
            MenuMobile.append(btnClose);
            btnOpen.click(function (e) {
                e.preventDefault();
                $("#header .col-header-menu").addClass("open-menu");
            });
            $("#header .col-header-menu .btn-close").click(function (e) {
                e.preventDefault();
                $("#header .col-header-menu").removeClass("open-menu");
            });

        }
    },
    sommaire: function () {
        $(function () {
            $('.list-sommaire a[href^="#"]:not([href="#"])').click(function () {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.substr(1) + ']');
              if (target.length) {
                $('html,body').animate({
                  scrollTop: target.offset().top - 290
                }, 1000);
                return false;
              }
            });
          });
    },
    MenuElementsHover: function () {
        var width = $(window).width();
        if (width > 992) {
            $("div[id^='pelement-']").hover(function() {
                $('.x-product').hide().removeClass('active');
                var a = this.id.split('-')[1];
                $('#pcontent-' + a).parent().show();

            } );
            $("div[id^='oelement-']").hover(function() {
                $('.o-product').hide().removeClass('active');
                var a = this.id.split('-')[1];
                $('#ocontent-' + a).parent().show();
            } );
            $("div[id^='aelement-']").hover(function() {
                $('.a-product').hide().removeClass('active');
                var a = this.id.split('-')[1];
                $('#acontent-' + a).show();
                $('#alink-' + a).parent().show();
            } );
        }
    },
    CompoBlocHover: function () {
        var width = $(window).width();
        if (width > 992) {
            $(".bloc-composition-image .composition-list ul li[id^='compo-']").hover(function() {
                $('.bloc-composition-content').hide().removeClass('active');
                var a = this.id.split('-')[1];
                $('#pcompo-' + a).show();
            } );
        }else {
            $(".bloc-composition-image .composition-list ul li[id^='compo-']").click(function() {
                $('.bloc-composition-content').hide().removeClass('active');
                var a = this.id.split('-')[1];
                $('#pcompo-' + a).show();
            } );
        }
    }
};
