import {Helpers} from '../Helpers';

export const Home = {
    init: function () {
        this.slickhome();
        this.homeProuctsSlider();
        this.filterhome();
        this.select();
        this.qty();
        this.hometemo();
    },
  
    slickhome: function() {
        var width = $(window).width();
        if (width < 992) {
            $('.etape-production').not('.slick-initialized').slick({
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }); 
        }
    },
    homeProuctsSlider: function() {
        var width = $(window).width();
        if (width < 992) {
            $('.blockproducts .products, .interesser .produit products').not('.slick-initialized').slick({
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                responsive: [
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }); 
        }
    },
    hometemo: function() {
  
        $('.Offerces .slider-wrapper').not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
        }); 
    },
    filterhome: function() {
        var width = $(window).width();
        if (width > 992) {
        $('.blockproducts:first').show();
        $('.sidebar .title-light').hover(function() {
            var text = $(this).text();
            var remove_space = text.replace(/\s+/g,'');
            var remove_space = remove_space.replace('&','');
            $('.blockproducts').hide();
            $(`.blockproducts#${remove_space}`).show();
        })
        }
    },
    select: function() {
        var width = $(window).width();
        if (width < 992) {
            $('.blockproducts:first').addClass('active');
            $('.sidebar .list-unstyled').on('change', function() {
                var text = $(this).val();
                var remove_space = text.replace(/\s+/g,'');
                var remove_space = remove_space.replace('&','');
                $('.blockproducts').removeClass('active');
                $(`.blockproducts#${remove_space}`).addClass('active');
            });
            this.homeProuctsSlider();
        }
    },
    qty: function() {
        $('.col-add-qty .qty_precise').on('click', function() {
           $('.col-add-qty  .qty ').toggle();
        });
    },
};