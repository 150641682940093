import { Home } from "./pages/Home";
import { Category } from "./pages/Category";
import { Product } from "./pages/Product";
import { Header } from "./components/Header";
import { Global } from "./Global";
import "./lib/slick-carousel/slick.min";

$(document).ready(function () {
  switch ($("body").attr("id")) {
    case "index":
      Home.init();
      break;
    case "category":
      Category.init();
      break;
    case "product":
      Product.init();
      break;
  }
  Header.init();
  Global.init();
  CloseModalAddToCart();
  blog();
  $("button[name='submitNewsletter']").click(function () {
    var mail = $(".input-subscription").val();
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (regex.test(mail)) {
      $(".error-news").hide();
      return true;
    } else {
      $(".error-news").html("Invalid email");
      return false;
    }
  });
  faq();
  tabs();
  fpmobiletabs();
  temolisting();
  fpProuctsSlider();
  selectpanier(1);
  linkactifs();
  homereassurance();
  $('.year-events-wrapper').hide();
  $('div.year-events-wrapper').first().show().addClass('isLast');
  // $('year-events-wrapper:nth-last-of-type(2)').show();
  $('.loadmore').on('click',function(){
    setTimeout(() => {
      var y = 0;
      y = $('.year-events-wrapper.isLast').last().data('year');
      y = y-1;
      $('div.year-events-wrapper.'+y+'').show().addClass('isLast');
    }, 500);
      
  });
  $("#delete_prd").submit(function (e) {
    e.preventDefault(); // avoid to execute the actual submit of the form.

    var form = $(this);
    var actionUrl = form.attr("action");
    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
      success: function (data) {
        $("#add_cure_prd").submit();
      },
    });
  });
  if ($(".display-header-bg").length) {
    $("body").addClass("headerbg");
  }
  // var url = window.location.href;
  // var id = url.substring(url.lastIndexOf('#') + 1);
  // let headerHeight = $('#header').outerHeight();
  // $('#',+id).attr( "id", id );
  // setTimeout(function(){
  //     $('html, body').animate({
  //         scrollTop: $('#',+id).offset().top - headerHeight,
  //     }, 500);
  // },500);
  if ($(".sommaire").length) {
    $("body").addClass("sommaire");
  }
  if ($(".engagement").length) {
    $("body").addClass("engagement");
  }
  if ($(".temoignage").length) {
    $("body").addClass("temoignage");
  }

  $(".pos-breadcrumb").append($("nav.breadcrumb"));
  $(".product-pack-cart .add-to-cart2").click(function (e) {
    e.preventDefault();
    var Qte = $(".qty_discount").val();
    $(".product-add-to-cart input.input-group").val(Qte);
    $("div.product-actions form div.col-add-btn .add-to-cart").trigger("click");
  });
  $(".product-pack-labelDisc .qty_discount").click(function (e) {
    e.preventDefault();

    $(".product-pack-labelDisc").removeClass("checked");
    var Qte = $(this).val();
    $(".product-add-to-cart input.input-group").val(Qte);
    $(this).parents(".product-pack-labelDisc").addClass("checked");
  });
  if ($(".product-pack-group2").length) {
    $(".product-actions").addClass("hide");
  }

  $(document).ajaxSuccess(function (event, request, settings) {
    setTimeout(function () {
      $("#blockcart-close").click(function (e) {
        $(this).parents("#blockcart-notification").addClass("ns-hide");
      });
    }, 500);
  });
  $("#blockcart-close").click(function (e) {
    $(this).parents("#blockcart-notification").addClass("ns-hide");
  });
  $(".slide-products")
    .not(".slick-initialized")
    .slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    });
  $(".slide-popin")
    .not(".slick-initialized")
    .slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  $(".slide-popin-cart")
    .not(".slick-initialized")
    .slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

  $(document).ajaxSuccess(function (event, request, settings) {
    setTimeout(() => {
      $(".slide-popin, .ns-box-inner .products")
        .not(".slick-initialized")
        .slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
    }, 200);
    selectpanier(2);
  });
  function blog() {
    $("ul.list-unstyled").on("click", ".init", function () {
      $(this).closest("ul.list-unstyled").children("li:not(.init)").toggle();
    });

    var allOptions = $("ul.list-unstyled").children("li:not(.init)");
    $("ul.list-unstyled").on("click", "li:not(.init)", function () {
      allOptions.removeClass("selected");
      $(this).addClass("selected");
      $("ul.list-unstyled").children(".init").html($(this).html());
      allOptions.toggle();
    });
    if ($(window).width() < 992) {
      if ($(".simpleblog__share").length) {
        $(".simpleblog__share").insertAfter($(".left-bloc"));
      }
    }
  }

  function faq() {
    const accordionBtns = document.querySelectorAll(".item-header");
    accordionBtns.forEach((accordion) => {
      accordion.onclick = function () {
        this.classList.toggle("active");

        let content = this.nextElementSibling;

        if (content.style.maxHeight) {
          //this is if the accordion is open
          content.style.maxHeight = null;
        } else {
          //if the accordion is currently closed
          content.style.maxHeight = content.scrollHeight + "px";
        }
      };
    });
  }
  function tabs() {
    var width = $(window).width();
    if (width > 992) {
      const tabs = document.querySelectorAll(
        ".elementor-tabs .nav-tabs .nav-item"
      );
      $(".tab_content:not('.O_T')").hide();
      $(".tab_content.O_T").show().css({
        'visibility': 'hidden',
        'opacity': '0',
        'position': 'absolute',
      });
      setTimeout(() => {
        $(".tab_content.O_T").hide().css({
          'visibility': 'visible',
          'opacity': '1',
          'position': 'static',
        });
      }, 500);
      $(".tab_content.efficacite").show();
      tabs.forEach((tab) => {
        tab.onclick = function () {
          var tabnmb = $(this).find(".nav-link").data("tab");
          if (tabnmb == 1) {
            $(".tab_content").hide();
            $(".tab_content.efficacite").show();
          } else if (tabnmb == 2) {
            $(".tab_content").hide();
            $(".tab_content.bienfaits").show();
          } else if (tabnmb == 3) {
            $(".tab_content").hide();
            $(".tab_content.utilisation").show();
          } else if (tabnmb == 4) {
            $(".tab_content").hide();
            $(".tab_content.utilise").show();
          } else if (tabnmb == 5) {
            $(".tab_content").hide();
            $(".tab_content.composition").show();
          } else if (tabnmb == 6) {
            $(".tab_content").hide();
            $(".tab_content.O_T").show();
          } else if (tabnmb == 7) {
            $(".tab_content").hide();
            $(".tab_content.FAQ").show();
          }
        };
      });
    }
  }
  function CloseModalAddToCart() {
    $("#blockcart-notification #blockcart-close").click(function () {
      $("#blockcart-notification").addClass("hide");
    });
    $(document).ajaxSuccess(function (event, request, settings) {
      setTimeout(() => {
        $("#blockcart-notification #blockcart-close").click(function () {
          $("#blockcart-notification").addClass("hide");
        });
        $(".slide-popin-cart")
          .not(".slick-initialized")
          .slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          });
      }, 200);
    });
    $("#blockcart-notification:before").click(function () {
      $("#blockcart-notification").addClass("hide");
    });
  }
  $("#category #left-column .btn.filtre").click(function (e) {
    e.preventDefault();
    $("#left-column .PM_ASBlockOutput").addClass("open-filter");
  });
  $("#category #left-column .PM_ASBlockOutput .btn-close").click(function (e) {
    e.preventDefault();
    $("#left-column .PM_ASBlockOutput").removeClass("open-filter");
  });
  function fpmobiletabs() {
    var width = $(window).width();
    if (width < 992) {
      $(".tab_content .tabs-mobile ").on("click", function () {
        $(this).parents(".tab_content").toggleClass("active");
      });
    }
  }
  function temolisting() {
    var width = $(window).width();
    if (width < 767) {
      $(".cart-avis-listing .products .slider-wrapper")
        .not(".slick-initialized")
        .slick({
          arrows: false,
          dots: true,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
    }
  }
  function fpProuctsSlider() {
    var width = $(window).width();
    if (width < 992) {
      $(".interesser .produit .products")
        .not(".slick-initialized")
        .slick({
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
    }
  }
  function selectpanier(index) {
    var list = $('.js-dropdown-list');
    var link = $('.js-link');
    $(document).on('click', '.js-link', function (e) {
      e.preventDefault();
      $(this).parent().find(list).slideToggle(200);
    });
    $(document).on('click', ".js-dropdown-list li", function () {
      var text = $(this).html();
      var icon = '<i class="fa fa-angle-down cbp-submenu-aindicator"></i>';
      $(this).parents('.dropdown').find(link).html(text + icon);
      $(this).parent().slideUp(200);

      if (text === '* Reset') {
        link.html('Select one option' + icon);
      }
      if(index == 1) {
        updateValues($(this));
      }
      
    });
  }
  function homereassurance() {
    var width = $(window).width();
    if (width < 992) {
        $('#index .reassurance-items-wrapper,#category .reassurance-items-wrapper,body#cms  .reassurance-items-wrapper').not('.slick-initialized').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                }
            ]
        }); 
    }
    if (width < 992) {
        $('body#cms  .products-actife').not('.slick-initialized').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            dots: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        }); 
    }
}
  function linkactifs() {
    $('.Links-wrapper li a').map(function () {
        var id = $(this).attr('href');
        var id_2 = id.substring(id.lastIndexOf('#') + 1);
        if ( $('.title-target').hasClass(id_2)) {
            $('.title-target.'+id_2).attr( "id", id_2 );
        }
    })
      $('.Links-wrapper li a').on('click', function (e) {
            e.preventDefault();
            var id = $(this).attr('href');
            let headerHeight = $('#desktop-header').outerHeight();
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: eval($(document).find(id).offset().top - headerHeight),
                }, 500);
            },500);

      })
  }
  
  
  function updateValues($this) {
    var current = parseInt($this.data('current'));
    var id = $this.data('id');
    var attr = $this.data('attr');
    var qunt = parseInt($this.data('value'));
    var id_product = id;
    var id_customization = attr;
    var down = $this.data('down');
    var up = $this.data('up');

    if (qunt > current) {
      var urlUpdate = up;
      var qty = parseInt(qunt) - parseInt(current);
      var op = 'up';
    } else if (qunt == current) {
      return '';
    } else {
      var urlUpdate = down;
      var qty = parseInt(current) - parseInt(qunt);
      var op = 'down';
    }

    $.ajax({
      type: "POST",
      url: urlUpdate,
      data: {
        ajax: 1,
        qty: qty,
        action: 'update',
        op: op
      },
      success: function success(data) {
        var dt = $.parseJSON(data);
        var idProduct = dt['id_product'];
        var qnty = dt['quantity'];
        var prdName = $('#name' + idProduct).val();
        var prdCat = $('#cat' + idProduct).val();
        var prdPrice = $('#price' + idProduct).val();

        if (qunt > current) {
          dataLayer.push({
            'event': 'dl-bioenergies-addToCart',
            'ecommerce': {
              'currencyCode': 'EUR',
              'add': {
                'products': [{
                  'name': prdName,
                  'id': idProduct,
                  'price': prdPrice,
                  'brand': 'Bioénergie',
                  'category': prdCat,
                  'variant': 'o',
                  'quantity': qunt - current
                }]
              }
            }
          });
        } else {
          window.dataLayer.push({
            'event': 'dl-bioenergies-removeFromCart',
            'ecommerce': {
              'remove': {
                'products': [{
                  'name': prdName,
                  'id': idProduct,
                  'price': prdPrice,
                  'brand': 'Bioénergie',
                  'category': prdCat,
                  'variant': 'o',
                  'quantity': current - qunt
                }]
              }
            }
          });
        }

        location.reload(true);
      }
    }); //T
  }

  $('.brand-slide').not('.slick-initialized').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true
      }
    }]
  });
  var layers = $('.datalayer');
  var prd = [];
  $('.datalayer').each(function () {
    //if statement here 
    // use $(this) to reference the current div in the loop
    //you can try something like...
    var idProduct = $(this).data("id");
    var prdName = $('#name' + idProduct).val();
    var prdCat = $('#cat' + idProduct).val();
    var prdPrice = $('#price' + idProduct).val();
    var prdd = {
      'name': prdName,
      'id': idProduct,
      'price': prdPrice,
      'brand': "Bioénergie",
      'category': prdCat,
      'variant': 'o',
      'list': 'Search Results'
    };
    prd.push(prdd);
  });

  if (typeof prd != "undefined" && prd.length > 0) {
    dataLayer.push({
      'event': 'dl-bioenergies-viewlist',
      'ecommerce': {
        'currencyCode': 'EUR',
        'impressions': prd
      }
    });
  }

  $('.datalayer').click(function () {
    var idProduct = $(this).data("id");
    var prdName = $('#name' + idProduct).val();
    var prdCat = $('#cat' + idProduct).val();
    var prdPrice = $('#price' + idProduct).val();
    dataLayer.push({
      'event': 'dl-bioenergies-productClick',
      'ecommerce': {
        'click': {
          'actionField': {
            'list': 'Search Results'
          },
          'products': [{
            'name': prdName,
            'id': idProduct,
            'price': prdPrice,
            'brand': "Bioénergie",
            'category': prdCat,
            'variant': "o",
            'position': 1
          }]
        }
      },
      'eventCallback': function eventCallback() {}
    });
  });
  $('body').on('change', '.js-cart-line-product-quantity', function () {
    var prdId = $(this).data('product-id');
    var quantityW = $(this).val();
    var qntCart = $('#' + prdId + '_qty').val();
    var prdName = $('#' + prdId + '_name').val();
    var prdCat = $('#' + prdId + '_cat').val();
    var prdPrice = $('#' + prdId + '_price').val();

    if (quantityW - qntCart > 0) {
      $('#' + prdId + '_qty').val(quantityW);
      dataLayer.push({
        'event': 'dl-bioenergies-addToCart',
        'ecommerce': {
          'currencyCode': 'EUR',
          'add': {
            'products': [{
              'name': prdName,
              'id': prdId,
              'price': prdPrice,
              'brand': 'Bioénergie',
              'category': prdCat,
              'variant': 'o',
              'quantity': quantityW - qntCart
            }]
          }
        }
      });
    } else {
      $('#' + prdId + '_qty').val(quantityW);
      var prdName = $('#' + prdId + '_name').val();
      var prdCat = $('#' + prdId + '_cat').val();
      var prdPrice = $('#' + prdId + '_price').val();
      var prdId = $('#' + prdId + '_id').val();
      window.dataLayer.push({
        'event': 'dl-bioenergies-removeFromCart',
        'ecommerce': {
          'remove': {
            'products': [{
              'name': prdName,
              'id': prdId,
              'price': prdPrice,
              'brand': 'Bioénergie',
              'category': prdCat,
              'variant': 'o',
              'quantity': qntCart - quantityW
            }]
          }
        }
      });
    }
  });
  prestashop.on('updateCart', function (event) {
    var qntyy = $('#quantity_wanted').val();

    if (event.reason.linkAction != 'delete-from-cart' && typeof event.resp != 'undefined') {
      var prdId = $('#prdid').val();
      var prdName = $('#prdName').val();
      var prdCat = $('#prdCat').val();
      var prdPrice = $('#prdPrice').val();

      if (typeof prdName == 'undefined') {
        var prdName = $('#name' + event.resp.id_product).val();
        var prdCat = $('#cat' + event.resp.id_product).val();
        var prdPrice = $('#price' + event.resp.id_product).val();
        qntyy = 1;
      }

      gtag('event', 'add_to_cart', {
        'value': prdPrice,
        'items': [{
          'id': '3868-236',
          'google_business_vertical': 'retail'
        }]
      });
      dataLayer.push({
        'event': 'dl-bioenergies-addToCart',
        'ecommerce': {
          'currencyCode': 'EUR',
          'add': {
            'products': [{
              'name': prdName,
              'id': prdId,
              'price': prdPrice,
              'brand': 'Bioénergie',
              'category': prdCat,
              'variant': 'o',
              'quantity': qntyy
            }]
          }
        }
      });
    }

    if (event.reason.linkAction == 'delete-from-cart') {
      var prdName = $('#' + event.reason.idProduct + '_name').val();
      var prdCat = $('#' + event.reason.idProduct + '_cat').val();
      var prdPrice = $('#' + event.reason.idProduct + '_price').val();
      var prdId = $('#' + event.reason.idProduct + '_id').val();
      var prdQty = $('#' + event.reason.idProduct + '_qty').val();
      window.dataLayer.push({
        'event': 'dl-bioenergies-removeFromCart',
        'ecommerce': {
          'remove': {
            'products': [{
              'name': prdName,
              'id': prdId,
              'price': prdPrice,
              'brand': 'Bioénergie',
              'category': prdCat,
              'variant': 'o',
              'quantity': prdQty
            }]
          }
        }
      });
      $.ajax({
        url: "/fr/panier?ajax=1&action=refresh",
        method: "POST",
        data: {
          id_product: event.reason.idProduct,
          id_product_attribute: 0
        }
      }).done(function (html) {
        $(".cart-container").html(html.cart_detailed);
        $('.cart-summary').html(html.cart_detailed_totals);
      });
    }
  });
  prestashop.on('changedCheckoutStep', function (event) {
    $('.checkout-step').each(function (index) {
      if ($(this).hasClass('-current')) {
        Checkout();
      }
    });
  });
  Checkout();

  function Checkout() {
    $('.checkout-step').each(function (index) {
      if ($(this).hasClass('-current')) {
        var products = prestashop.cart.products;
        var prds = [];
        products.forEach(function (prd) {
          var prdCat = $('#cat' + prd.id_product).val();
          var price = prd.price.replace("€", " ");
          var priceF = price.replace(",", ".");
          var prdd = {
            'name': prd.name,
            'id': prd.id,
            'price': priceF,
            'brand': prd.manufacturer_name,
            'category': prdCat,
            'variant': 'o',
            'quantity': prd.quantity
          };
          prds.push(prdd);
        });
        dataLayer.push({
          'event': 'dl-bioenergies-checkout',
          'ecommerce': {
            'checkout': {
              'actionField': {
                'step': index + 1,
                'option': 'o'
              },
              'products': prds
            }
          }
        });
      }
    });
  }
  function CommandeConfirmation(){
    var prds = [];
    $('.checkoutproducts').each(function(){
       var prdd = {
            'name': $(this).data("name"),
            'id': $(this).data("id"),
            'price': $(this).data("price"),
            'brand': 'Bioénergie',
            'category': $(this).data("cat"),
            'variant': 'o',
            'quantity': $(this).data("quantity"),
            'coupon': $(this).data("code"),
          }
          prds.push(prdd);
    });
    if (prds.length > 0) {
      var orderId = $("input[name=idorderc]").val();
      var price = $("input[name=pricefloat]").val();
      var tax = $("input[name=tax]").val();
      var coupon = $("input[name=coupon]").val();
      var vaucher =  $(".vaucher").val();
      var shipp= $("input[name=shipping]").val();
      gtag('event','purchase', {
        'value':price ,
        'items': [
          {
            'id': orderId, 
            'google_business_vertical': 'retail'
          }
        ]
      });
      dataLayer.push({
        'event': 'dl-bioenergies-purchase',
        'ecommerce': {
          'purchase': {
            'actionField': {
              'id': orderId,                         
              'affiliation': 'o',
              'revenue': price,                     
              'tax':tax,
              'shipping': shipp,
              'coupon': vaucher
            },
            'products': prds
          }
        }
      });
    }
    
  }
  CommandeConfirmation();
});

