import { Global } from "../Global";

export const Product = {
  init: function () {
    reviewScroll();
    readMore();
    fpqty();
    // fpReassurance();
    trustpilotmove();
    efficacite();
    // fpmobiletabs();

    if ($(window).width() < 992) {
      Global.initSwiper(".slick-reassurance");
    }

    $("#product .fp-accordion-title:first-of-type").addClass("open");
    $("#product .fp-accordion-body:first").show();

    // function orderProduct() {
    //     $( ".product_p_price_container" ).insertAfter( ".col-add-qty" );
    //     $( ".product-pack" ).insertAfter( ".product-actions" );

    //     if ($(window).width() < 768) {
    //         $( ".payment-action" ).insertAfter( ".product-pack" );
    //     }
    // }

    function reviewScroll() {
      let headerHeight = $("#header").outerHeight();
      setTimeout(function () {
        $("#trustpilot-widget-trustbox-1-wrapper").on("click", function () {
          $("html, body").animate(
            {
              scrollTop: $(".avis").offset().top - headerHeight,
            },
            500
          );
        });
      }, 500);
    }
    function readMore() {
      $(".product-description-all .show-more").click(function () {
        var $this = $(this);
        $this.toggleClass("show-more");

        if ($this.hasClass("show-more")) {
          $(".product-description-all .inner").removeClass("opened", 300);
          $(this).text("En savoir plus");
        } else {
          $(".product-description-all .inner").addClass("opened", 300);
          $(this).text("En savoir moins");
        }
      });
    }
    function fpqty() {
      var isopen = false;
      var qty = $(".product-pack-labelDisc.checked").data("qty");
      if (qty) {
        $("#quantity_wanted").val(qty);
      } else {
        $("#quantity_wanted").val(1);
      }
      $(".col-add-qty .qty_precise").on("click", function () {
        if (isopen == false) {
          $(".col-add-qty  .qty ").show();
          isopen = true;
        } else {
          $(".col-add-qty  .qty ").hide();
          var qty = $(".product-pack-labelDisc.checked").data("qty");
          if (qty) {
            $("#quantity_wanted").val(qty);
          } else {
            $("#quantity_wanted").val(1);
          }
          isopen = false;
        }
      });
    }
    
    function trustpilotmove() {
      setTimeout(() => {
        $("#trustpilot-widget-trustbox-1-wrapper").insertAfter(
          ".utilise .elementor-container .elementor-widget-wrap .tabs-mobile"
        );
      }, 500);
    }
    function efficacite() {
       $(".chart.graph").map(function() { // get canvas
          var width = $(window).width();
          if (width < 767) {
            var options = {
              percent: $(this).data("percent") || 25,
              size: $(this).data("size") || 80,
              lineWidth: $(this).data("line") || 8,
              rotate: $(this).data("rotate") || 0,
            };
          }else {
            var options = {
              percent: $(this).data("percent") || 25,
              size: $(this).data("size") || 106,
              lineWidth: $(this).data("line") || 11,
              rotate: $(this).data("rotate") || 0,
            };
          }
          var canvas = document.createElement("canvas");
          var span = document.createElement("span");
          span.textContent = options.percent.trim() +"%";
    
          if (typeof G_vmlCanvasManager !== "undefined") {
            G_vmlCanvasManager.initElement(canvas);
          }
    
          var ctx = canvas.getContext("2d");
          canvas.width = canvas.height = options.size;
    
          $(this).append(span);
          $(this).append(canvas);
    
          ctx.translate(options.size / 2, options.size / 2); // change center
          ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI); // rotate -90 deg
    
          //imd = ctx.getImageData(0, 0, 240, 240);
          var radius = (options.size - options.lineWidth) / 2;
    
          var drawCircle = function (color, lineWidth, percent) {
            percent = Math.min(Math.max(0, percent || 1), 1);
            ctx.beginPath();
            ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false);
            ctx.strokeStyle = color;
            ctx.lineCap = "round"; // butt, round or square
            ctx.lineWidth = lineWidth;
            ctx.stroke();
          };
    
          drawCircle("#EEE9E0", options.lineWidth, 100 / 100);
          drawCircle("#55BE93", options.lineWidth, options.percent / 100);
      });

      
    }
    
  },
};
